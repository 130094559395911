import SAMPLE_APP from '../../static/images/sampleApp.svg';
import DEMO from '../../static/images/DemoImage.svg';

export const DemoAppsItems = [
  {
    title: 'Credit Score Sample App',
    image: SAMPLE_APP,
    description:
      'See Skyflow’s end-to-end tokenization solution in action in this sample card issuing app.',
    link: 'https://creditcard-demo.skyflow.app/',
  },
  {
    title: 'Tokenization Demo',
    image: DEMO,
    description:
      'See how Skyflow can reduce your business’s PCI compliance scope using tokenization.',
    link: 'https://tokenization-demo.skyflow.app/',
  },
  {
    title: 'Governance Demo',
    image: DEMO,
    description:
      'See how Skyflow can be used to securely share sensitive data with internal and external teams.',
    link: 'https://governance-demo.skyflow.app/',
  },
];
